<template>
  <div class="page1">
    <div class="info" v-if="closeShow">
      <el-row>
        <el-col :span="24">
          <div class="info_content">
            <el-col :span="23">
              <span
                >停车收入报表，按支付时间统计收入和退款信息，异常处理订单数据不影响本报表；本报表适用于财务人员了解车场收入情况。</span
              >
            </el-col>
            <el-col :span="1">
              <img src="./img/close.png" @click="closeShow = false" alt="" />
            </el-col>
          </div>
        </el-col>
      </el-row>
    </div>
    <!--主体内容-->
    <div class="content bgFFF">
      <!--搜索条件区域-->
      <div class="searchWrapper">
        <div class="search_box_title">{{ $t('searchModule.Query_Table') }}</div>
        <el-form
          :inline="true"
          status-icon
          label-position="right"
          label-width="75px"
          :model="formInline"
          ref="formInline"
          class="demo-form-inline"
        >
          <div class="col_box">
            <div class="col_left">
              <el-form-item :label="$t('searchModule.Date_search')">
                <a-date-picker
                  ref="datePicker"
                  :YearShowHidden="selectkeys[3]"
                  :selectkeys="selectkeys"
                  :previousDate="previousDate"
                  :nextDate="nextDate"
                ></a-date-picker>
              </el-form-item>
              <el-form-item :label="$t('searchModule.region')" prop="areaId" label-width="70px">
                <a-cascader ref="cascader" @change="parkClear"></a-cascader>
              </el-form-item>
              <el-form-item :label="$t('searchModule.Merchant_affiliation')" label-width="90px">
                <a-operation-select ref="operationSelect"></a-operation-select>
              </el-form-item>
              <el-form-item :label="$t('searchModule.Road_or_Parking_Lot')" label-width="75px">
                <my-component
                  ref="parkInput"
                  :areaIds="formInline.streetId ? formInline.streetId : formInline.areaId"
                  @valueChange="completeValue"
                  slaveRelations="0,1"
                >
                </my-component>
              </el-form-item>

              <el-form-item :label="$t('searchModule.Type_of_parking_lot')" label-width="90px">
                <a-park-type-select ref="parkTypeSelect"></a-park-type-select>
              </el-form-item>

              <el-form-item :label="$t('searchModule.Commercial_status')" prop="commercialStatus" label-width="90px">
                <el-select
                  v-model="formInline.commercialStatus"
                  collapse-tags
                  multiple
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in options"
                    :key="item.code"
                    :label="item.desc"
                    :value="item.code"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </div>
            <div class="col_right">
              <el-button
                type="primary"
                icon="el-icon-search"
                @click="
                  pageNum = 1;
                  searchData();
                "
                :loading="loading"
                >查询
              </el-button>
            </div>
          </div>
          <div class="col_box_boder" v-if="$route.meta.authority.button.export"></div>
          <div class="col_box h44">
            <div class="col_left"></div>
            <div class="col_right mbd4">
              <exportFile
                :exportData="exportData"
                v-if="$route.meta.authority.button.export"
              ></exportFile>
            </div>
          </div>
        </el-form>
      </div>

      <div class="statusContent">
        <div class="status_info">
          <div class="status_img">
            <img src="./img/urlQ.png" alt="" />
          </div>
          <div class="status_word">
            <p>
              <span>应收合计</span>
              <el-tooltip
                class="item"
                effect="dark"
                content="停车支付订单应收金额总计（含预付金额）"
                placement="top-start"
              >
                <img src="./img/tip.png" alt="" />
              </el-tooltip>
            </p>
            <p>
              {{ statusList.totalShouldPayMoney | moneyHandling() }}
              <span>元</span>
            </p>
          </div>
        </div>
        <!-- <div class="status_info">
          <div class="status_img">
            <img src="./img/urlW.png" alt="">
          </div>
          <div class="status_word">
            <p>
              支付实收合计
              <el-tooltip class="item" effect="dark" content="当日实收+场中支付未出场金额+支付往日金额" placement="top-start">
                <img src="./img/tip.png" alt="">
              </el-tooltip>
            </p>
            <p>
              {{ statusList.totalActualPayMoneyAll | moneyHandling() }}
              <span>元</span>
            </p>
          </div>
        </div> -->
        <div class="status_info">
          <div class="status_img">
            <img src="./img/urlE.png" alt="" />
          </div>
          <div class="status_word">
            <p>
              <span>优惠券合计</span>
              <el-tooltip
                class="item"
                effect="dark"
                content="停车支付订单中优惠券金额总计"
                placement="top-start"
              >
                <img src="./img/tip.png" alt="" />
              </el-tooltip>
            </p>
            <p>
              {{ statusList.totalAgioMoney | moneyHandling() }}
              <span>元</span>
            </p>
          </div>
        </div>
        <div class="status_info">
          <div class="status_img">
            <img src="./img/urlQ.png" alt="" />
          </div>
          <div class="status_word">
            <p>
              <span>收入合计</span>
              <el-tooltip
                class="item"
                effect="dark"
                content="停车支付订单中实付金额金额总计"
                placement="top-start"
              >
                <img src="./img/tip.png" alt="" />
              </el-tooltip>
            </p>
            <p>
              {{ statusList.totalActualPayMoney | moneyHandling() }}
              <span>元</span>
            </p>
          </div>
        </div>
        <div class="status_info">
          <div class="status_img">
            <img src="./img/urlR.png" alt="" />
          </div>
          <div class="status_word">
            <p>
              <span>退款合计</span>
              <el-tooltip
                class="item"
                effect="dark"
                content="退优惠金额+退实付金额的值累加"
                placement="top-start"
              >
                <img src="./img/tip.png" alt="" />
              </el-tooltip>
            </p>
            <p>
              {{ statusList.totalRefundMoney | moneyHandling() }}
              <span>元</span>
            </p>
          </div>
        </div>
        <div class="status_info">
          <div class="status_img">
            <img src="./img/urlW.png" alt="" />
          </div>
          <div class="status_word">
            <p>
              <span>欠费合计</span>
              <el-tooltip
                class="item"
                effect="dark"
                content="欠费金额的值累加"
                placement="top-start"
              >
                <img src="./img/tip.png" alt="" />
              </el-tooltip>
            </p>
            <p>
              {{ statusList.totalDebtMoney | moneyHandling() }}
              <span>元</span>
            </p>
          </div>
        </div>
        <!-- <div class="status_info"></div> -->
        <!-- <div class="status_info"></div> -->
        <div class="status_info switchType">
          <p @click="switchClick">
            <span :class="switchNum != 0 ? 'blueWord' : ''"
              >{{ switchNum == 0 ? "展开" : "收起" }}
            </span>
            <img v-show="switchNum == 0" src="./img/fold.png" alt="" />
            <img v-show="switchNum == 1" src="./img/hidden.png" alt="" />
          </p>
        </div>
      </div>
      <div class="amountContents" v-show="switchNum != 0">
        <el-row :gutter="24">
          <el-col :span="12">
            <el-row :gutter="5">
              <el-col :span="2">
                <div class="amount_left">
                  <p>路内</p>
                </div>
              </el-col>
              <el-col :span="22">
                <el-row :gutter="5" style="margin-bottom: 5px">
                  <el-col :span="6">
                    <div class="amount_box">
                      <p>应收合计</p>
                      <p>
                        {{ statusList.insideSubTotalShouldPayMoney | moneyHandling() }}
                        <span>元</span>
                      </p>
                    </div>
                  </el-col>
                  <!-- <el-col :span="6">
                    <div class="amount_box">
                      <p>支付实收合计</p>
                      <p>
                        {{ statusList.insideSubTotalActualPayMoney | moneyHandling() }}
                        <span>元</span>
                      </p>
                    </div>
                  </el-col> -->
                  <el-col :span="6">
                    <div class="amount_box">
                      <p>优惠合计</p>
                      <p>
                        {{ statusList.insideSubTotalAgioMoney | moneyHandling() }}
                        <span>元</span>
                      </p>
                    </div>
                  </el-col>
                  <el-col :span="6">
                    <div class="amount_box">
                      <p>收入合计</p>
                      <p>
                        {{ statusList.insideSubTotalActualPayMoney | moneyHandling() }}
                        <span>元</span>
                      </p>
                    </div>
                  </el-col>
                  <el-col :span="6">
                    <div class="amount_box">
                      <p>欠费合计</p>
                      <p>
                        {{ statusList.insideSubTotalDebtMoney | moneyHandling() }}
                        <span>元</span>
                      </p>
                    </div>
                  </el-col>
                </el-row>
                <el-row :gutter="5">
                  <el-col :span="6">
                    <div class="amount_box">
                      <p>
                        <span>单泊位实付</span>
                        <el-tooltip
                          class="item"
                          effect="dark"
                          content="单泊位应收累加值"
                          placement="top-start"
                        >
                          <img src="./img/tip.png" alt="" />
                        </el-tooltip>
                      </p>
                      <p>
                        {{ statusList.insideBerthAvgShouldPayMoney | moneyHandling() }}
                        <span>元</span>
                      </p>
                    </div>
                  </el-col>
                  <el-col :span="6">
                    <div class="amount_box">
                      <p>
                        <span>退实付合计</span>
                        <!-- <el-tooltip
                          class="item"
                          effect="dark"
                          content="单泊位应收累加值"
                          placement="top-start"
                        >
                          <img src="./img/tip.png" alt="" />
                        </el-tooltip> -->
                      </p>
                      <p>
                        {{ statusList.insideBerthAvgShouldPayMoney | moneyHandling() }}
                        <span>元</span>
                      </p>
                    </div>
                  </el-col>
                  <el-col :span="12">
                    <div class="amount_box">
                      <p>
                        <span>退款优惠券合计</span>
                        <!-- <el-tooltip
                          class="item"
                          effect="dark"
                          content="单泊位应收累加值"
                          placement="top-start"
                        >
                          <img src="./img/tip.png" alt="" />
                        </el-tooltip> -->
                      </p>
                      <p>
                        {{ statusList.insideBerthAvgShouldPayMoney | moneyHandling() }}
                        <span>元</span>
                      </p>
                    </div>
                  </el-col>
                  <!-- <el-col :span="12">
                    <div class="amount_box">
                      <p>
                        单泊位实收均值
                        <el-tooltip class="item" effect="dark" content="当日实收+场中支付未出场金额+支付往日金额" placement="top-start">
                          <img src="./img/tip.png" alt="">
                        </el-tooltip>
                      </p>
                      <p>
                        {{ statusList.insideBerthAvgActualPayMoney | moneyHandling() }}
                        <span>元</span>
                      </p>
                    </div>
                  </el-col> -->
                </el-row>
              </el-col>
            </el-row>
          </el-col>
          <el-col :span="12">
            <el-row :gutter="5">
              <el-col :span="2">
                <div class="amount_left">
                  <p>路外</p>
                </div>
              </el-col>
              <el-col :span="22">
                <el-row :gutter="5" style="margin-bottom: 5px">
                  <el-col :span="6">
                    <div class="amount_box">
                      <p>应收合计</p>
                      <p>
                        {{ statusList.outsideSubTotalShouldPayMoney | moneyHandling() }}
                        <span>元</span>
                      </p>
                    </div>
                  </el-col>
                  <!-- <el-col :span="6">
                    <div class="amount_box">
                      <p>支付实收合计</p>
                      <p>
                        {{ statusList.outsideSubTotalActualPayMoney | moneyHandling() }}
                        <span>元</span>
                      </p>
                    </div>
                  </el-col> -->
                  <el-col :span="6">
                    <div class="amount_box">
                      <p>优惠合计</p>
                      <p>
                        {{ statusList.outsideSubTotalAgioMoney | moneyHandling() }}
                        <span>元</span>
                      </p>
                    </div>
                  </el-col>
                  <el-col :span="6">
                    <div class="amount_box">
                      <p>收入合计</p>
                      <p>
                        {{ statusList.outsideSubTotalActualPayMoney | moneyHandling() }}
                        <span>元</span>
                      </p>
                    </div>
                  </el-col>
                  <el-col :span="6">
                    <div class="amount_box">
                      <p>欠费合计</p>
                      <p>
                        {{ statusList.outsideSubTotalDebtMoney | moneyHandling() }}
                        <span>元</span>
                      </p>
                    </div>
                  </el-col>
                </el-row>
                <el-row :gutter="5">
                  <el-col :span="6">
                    <div class="amount_box">
                      <p>
                        <span>单泊位实付</span>
                        <el-tooltip
                          class="item"
                          effect="dark"
                          content="单泊位应收累加值"
                          placement="top-start"
                        >
                          <img src="./img/tip.png" alt="" />
                        </el-tooltip>
                      </p>
                      <p>
                        {{ statusList.outsideBerthAvgShouldPayMoney | moneyHandling() }}
                        <span>元</span>
                      </p>
                    </div>
                  </el-col>
                  <el-col :span="6">
                    <div class="amount_box">
                      <p>
                        <span>退款优惠券合计</span>
                        <!-- <el-tooltip
                          class="item"
                          effect="dark"
                          content="单泊位应收累加值"
                          placement="top-start"
                        >
                          <img src="./img/tip.png" alt="" />
                        </el-tooltip> -->
                      </p>
                      <p>
                        {{ statusList.outsideBerthAvgShouldPayMoney | moneyHandling() }}
                        <span>元</span>
                      </p>
                    </div>
                  </el-col>
                  <el-col :span="12">
                    <div class="amount_box">
                      <p>
                        <span>退实付合计</span>
                        <!-- <el-tooltip
                          class="item"
                          effect="dark"
                          content="单泊位应收累加值"
                          placement="top-start"
                        >
                          <img src="./img/tip.png" alt="" />
                        </el-tooltip> -->
                      </p>
                      <p>
                        {{ statusList.outsideBerthAvgShouldPayMoney | moneyHandling() }}
                        <span>元</span>
                      </p>
                    </div>
                  </el-col>
                  <!-- <el-col :span="12">
                    <div class="amount_box">
                      <p>
                        单泊位实收均值
                        <el-tooltip class="item" effect="dark" content="当日实收+场中支付未出场金额+支付往日金额" placement="top-start">
                          <img src="./img/tip.png" alt="">
                        </el-tooltip>
                      </p>
                      <p>
                        {{ statusList.outsideBerthAvgActualPayMoney | moneyHandling() }}
                        <span>元</span>
                      </p>
                    </div>
                  </el-col> -->
                </el-row>
              </el-col>
            </el-row>
          </el-col>
        </el-row>
      </div>

      <!--列表区域-->
      <div class="tableWrapper paddingB20">
        <!-- 筛选按钮 -->
        <div class="switch">
          <screen
            :checkBoxGroup="colData"
            :checkedColumns="checkedColumns"
            :reportType="1"
            @selectChange="selectChange"
          ></screen>
        </div>
        <el-table
          :key="reload"
          header-cell-class-name="headerCallStyle"
          :cell-style="callStyle"
          v-loading="loading"
          :data="tableData"
          style="width: 100%"
        >
          <el-table-column
            label=""
            min-width="150"
            class-name="headerCellQ headerSize"
            v-if="colData[0].isTrue || colData[1].isTrue || colData[2].isTrue"
          >
            <el-table-column
              v-if="colData[0].isTrue"
              align="center"
              prop="reportDate"
              :label="$t('searchModule.date')"
              min-width="150"
            ></el-table-column>
            <el-table-column
              v-if="colData[1].isTrue"
              align="center"
              prop="areaName"
              :label="$t('searchModule.region')"
              min-width="150"
            ></el-table-column>
            <el-table-column
              v-if="colData[2].isTrue"
              align="center"
              prop="operationName"
              :label="$t('searchModule.Merchant_affiliation')"
              min-width="150"
            ></el-table-column>
          </el-table-column>
          <el-table-column
            align="center"
            class-name="headerCellQ headerSize "
            v-if="colData[3].isTrue || colData[4].isTrue || colData[5].isTrue || colData[6].isTrue"
          >
            <template slot="header">
              <span>道路/车场</span>
              <i class="iconfont icontishi"></i>
            </template>

            <el-table-column
              v-if="colData[3].isTrue"
              align="center"
              prop="parkName"
              :label="$t('searchModule.Road_or_Parking_Lot')"
              min-width="150"
              class-name=""
            ></el-table-column>
            <el-table-column
              v-if="colData[4].isTrue"
              align="center"
              prop="berthCount"
              label="泊位数"
              min-width="150"
            ></el-table-column>
            <el-table-column
              v-if="colData[5].isTrue"
              align="center"
              prop="parkTypeName"
              :label="$t('searchModule.Type_of_parking_lot')"
              min-width="150"
            ></el-table-column>
            <el-table-column
              v-if="colData[6].isTrue"
              align="center"
              prop="commercialStatusName"
              :label="$t('searchModule.Commercial_status')"
              min-width="150"
            ></el-table-column>
          </el-table-column>
          <el-table-column
            align="center"
            class-name="headerCellW headerSize  "
            v-if="colData[7].isTrue || colData[8].isTrue || colData[9].isTrue || colData[10].isTrue"
          >
            <template slot="header">
              <span style="vertical-align: middle">停车费收入</span>
              <el-tooltip
                class="item"
                effect="dark"
                content="数据来源：停车支付订单，按支付时间统计的值（含预付金额）"
                placement="top-start"
              >
                <img style="vertical-align: middle" src="./img/tip.png" alt="" />
              </el-tooltip>
            </template>

            <el-table-column
              v-if="colData[7].isTrue"
              align="center"
              prop="dailyTotalShouldPayMoney"
              label="收入合计（元）"
              min-width="150"
              class-name=""
            >
              <div slot-scope="scope">
                <span>{{ scope.row.dailyTotalShouldPayMoney | moneyHandling() }}</span>
              </div>
            </el-table-column>
            <el-table-column
              v-if="colData[8].isTrue"
              align="center"
              prop="agioMoney"
              label="优惠券金额（元）"
              min-width="150"
            >
              <div slot-scope="scope">
                <span>{{ scope.row.agioMoney | moneyHandling() }}</span>
              </div>
            </el-table-column>
            <el-table-column
              v-if="colData[9].isTrue"
              align="center"
              prop="actualPayMoney"
              label="实付金额（元）"
              min-width="150"
            >
              <div slot-scope="scope">
                <span>{{ scope.row.actualPayMoney | moneyHandling() }}</span>
              </div>
            </el-table-column>
            <el-table-column
              v-if="colData[10].isTrue"
              align="center"
              prop="singleBerthShouldPayMoney"
              label="单泊位收入（元）"
              min-width="150"
              class-name=""
            >
              <div slot-scope="scope">
                <span>{{ scope.row.singleBerthShouldPayMoney | moneyHandling() }}</span>
              </div>
            </el-table-column>
          </el-table-column>
          <el-table-column
            align="center"
            class-name="headerCellQ headerSize"
            v-if="colData[11].isTrue || colData[12].isTrue || colData[13].isTrue"
          >
            <template slot="header">
              <span style="vertical-align: middle">停车费退款</span>
              <el-tooltip
                class="item"
                effect="dark"
                content="数据来源：退款成功或异常处理退款处理完成时间在筛选范围的停车订单"
                placement="top-start"
              >
                <img style="vertical-align: middle" src="./img/tip.png" alt="" />
              </el-tooltip>
            </template>

            <el-table-column
              v-if="colData[11].isTrue"
              align="center"
              prop="dailyTotalRefundMoney"
              label="退款合计（元）"
              min-width="150"
            >
              <div slot-scope="scope">
                <span>{{ scope.row.dailyTotalRefundMoney | moneyHandling() }}</span>
              </div>
            </el-table-column>
            <el-table-column
              v-if="colData[12].isTrue"
              align="center"
              prop="refundAgioMoney"
              label="退优惠金额（元）"
              min-width="150"
            >
              <div slot-scope="scope">
                <span>{{ scope.row.refundAgioMoney | moneyHandling() }}</span>
              </div>
            </el-table-column>
            <el-table-column
              v-if="colData[13].isTrue"
              align="center"
              prop="refundActualPayMoney"
              label="退实付（元）"
              min-width="150"
            >
              <div slot-scope="scope">
                <span>{{ scope.row.refundActualPayMoney | moneyHandling() }}</span>
              </div>
            </el-table-column>
          </el-table-column>
          <el-table-column
            align="center"
            class-name="headerCellQ headerSize"
            v-if="colData[14].isTrue"
          >
            <el-table-column align="center" prop="debtMoney" label="欠费金额（元）" min-width="150">
              <div slot-scope="scope">
                <span>{{ scope.row.debtMoney | moneyHandling() }}</span>
              </div>
            </el-table-column>
          </el-table-column>
        </el-table>
        <!--分页器-->
        <div class="pagerWrapper bgFFF">
          <el-pagination
            v-if="total != 0"
            background
            @current-change="handleCurrentChange"
            :current-page="pageNum"
            :page-size="pageSize"
            layout="total, prev, pager, next, jumper"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { dateFormat, exportExcelNew } from "@/common/js/public.js";
import myComponent from "@/components/autocomplete/myautoComponent";
import exportFile from "@/components/exportFile/exportFile.vue";
import screen from "@/components/screen/index";
export default {
  name: "parkIncomeForm",
  data() {
    return {
      exportData: {},
      statusList: [],
      closeShow: true,
      screeningNum: 0,
      options: [],
      selectkeys: ["date", "month", "week", "year"],
      reload: 0,
      previousDate: new Date(),
      nextDate: new Date(),
      // colData中列出表格中的每一列，默认都展示
      colData: [
        { label: this.$t("list.date"), isTrue: false },
        { label: this.$t("list.region"), isTrue: false },
        { label: this.$t("list.Merchant_affiliation"), isTrue: false },
        { label: "道路/车场", isTrue: false },
        { label: "泊位数", isTrue: false },
        { label: this.$t("list.Type_of_parking_lot"), isTrue: false },
        { label: this.$t("list.Commercial_status"), isTrue: false },
        { label: "收入合计（元）", isTrue: false },
        { label: "优惠券金额（元）", isTrue: false },
        { label: "实付金额（元）", isTrue: false },
        { label: "单泊位收入（元）", isTrue: false },
        { label: "退款合计（元）", isTrue: false },
        { label: "退优惠（元）", isTrue: false },
        { label: "退实付（元）", isTrue: false },
        { label: "欠费金额（元）", isTrue: false },
      ],
      // 当前选中的多选框，代表当前展示的列
      checkedColumns: [],
      switchNum: 0,
      pageNum: 1,
      pageSize: 15,
      total: 0,
      parkList: [],
      tableData: [],
      loading: false,
      formInline: {
        commercialStatus: [],
      },
    };
  },
  watch: {},
  methods: {
    selectChange(selectList) {
      this.colData.filter((i, index) => {
        i.isTrue = false;
      });
      selectList.forEach((i) => {
        this.colData[i].isTrue = true;
      });
      this.searchData();
    },
    // 获取筛选项
    getReportFieldHide() {
      // 报表类型，1=临停报表；2=运营报表；3=停车卡报表；4=预约报表；5=营收报表；6=渠道统计；7=PDA统计
      this.$axios
        .get("acb/2.0/reportFieldHide/get", {
          data: {
            reportType: "1",
          },
        })
        .then((res) => {
          if (res.value.length == 1 && res.value[0] == "") {
            this.checkedColumns = [];
          } else {
            this.checkedColumns = res.value.map(Number);
          }
          this.checkedColumns.forEach((i) => {
            this.colData[i].isTrue = true
          });
        });
    },
    // 获取统计展示上半部分
    getStatusOneList() {
      this.$axios
        .get("acb/2.0/temporaryParkingReport/queryTotal", {
          data: {
            page: this.pageNum,
            pageSize: this.pageSize,
            reportType: this.$refs.datePicker ? this.$refs.datePicker.getDateType() : "",
            // parkId: this.$refs.parkSelect ? this.$refs.parkSelect.getParkId() : "",
            parkId: this.formInline.parkId,
            areaId: this.$refs.cascader ? this.$refs.cascader.getAreaId() : "",
            operationId: this.$refs.operationSelect
              ? this.$refs.operationSelect.getOperationId()
              : "",
            startDate: this.$refs.datePicker ? this.$refs.datePicker.getStartDate() : "",
            endDate: this.$refs.datePicker ? this.$refs.datePicker.getEndDate() : "",
            parkType: this.$refs.parkTypeSelect ? this.$refs.parkTypeSelect.getParkType() : "",
            commercialStatus: this.formInline.commercialStatus.toString(),
          },
        })
        .then((res) => {
          if (res.state == 0) {
            this.statusList = res.value;
          }
        });
    },
    // 获取商用状态
    getOptionType() {
      this.$axios.get("acb/2.0/temporaryParkingReport/droplist").then((res) => {
        this.options = res.value;
      });
    },
    // 列表点击事件
    screeningShow() {
      this.screeningNum = 1;
    },
    screeningHide() {
      this.screeningNum = 0;
    },
    parkClear() {
      this.$refs.parkSelect.clear();
    },
    // 展开收起
    switchClick() {
      if (this.switchNum == 0) {
        this.switchNum = 1;
      } else {
        this.switchNum = 0;
      }
    },
    // table 单元格样式动态设置
    callStyle(row) {
      return {
        "border-right": 0,
      };
    },
    moneyFormatter(row, column, cellValue, index) {
      if (cellValue) {
        return Number(cellValue / 100).toFixed(2);
      } else {
        return "0.00";
      }
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      this.searchData();
    },
    completeValue(item) {
      if (item) {
        this.formInline.parkId = item.parkId;
        this.formInline.parkName = item.parkName;
      } else {
        this.formInline.parkId = "";
        this.formInline.parkName = "";
      }
    },
    searchData() {
      this.exportExcelReport();
      this.getStatusOneList();
      this.$axios
        .get("/acb/2.0/temporaryParkingReport/list", {
          data: {
            page: this.pageNum,
            pageSize: this.pageSize,
            reportType: this.$refs.datePicker ? this.$refs.datePicker.getDateType() : "",
            // parkId: this.$refs.parkSelect ? this.$refs.parkSelect.getParkId() : "",
            parkId: this.formInline.parkId,
            areaId: this.$refs.cascader ? this.$refs.cascader.getAreaId() : "",
            operationId: this.$refs.operationSelect
              ? this.$refs.operationSelect.getOperationId()
              : "",
            startDate: this.$refs.datePicker ? this.$refs.datePicker.getStartDate() : "",
            endDate: this.$refs.datePicker ? this.$refs.datePicker.getEndDate() : "",
            parkType: this.$refs.parkTypeSelect ? this.$refs.parkTypeSelect.getParkType() : "",
            commercialStatus: this.formInline.commercialStatus.toString(),
          },
        })
        .then((res) => {
          this.total = res.value.total * 1;
          this.tableData = res.value.list;
        });
    },
    // 道路/车场收入报表 导出
    exportExcelReport() {
      let opt = {
        fileName: "停车收入报表",
        reportType: this.$refs.datePicker ? this.$refs.datePicker.getDateType() : "",
        // parkId: this.$refs.parkSelect ? this.$refs.parkSelect.getParkId() : "",
        parkId: this.formInline.parkId,
        areaId: this.$refs.cascader ? this.$refs.cascader.getAreaId() : "",
        operationId: this.$refs.operationSelect ? this.$refs.operationSelect.getOperationId() : "",
        startDate: this.$refs.datePicker ? this.$refs.datePicker.getStartDate() : "",
        endDate: this.$refs.datePicker ? this.$refs.datePicker.getEndDate() : "",
        parkType: this.$refs.parkTypeSelect ? this.$refs.parkTypeSelect.getParkType() : "",
        commercialStatus: this.formInline.commercialStatus.toString(),
        name: "停车收入报表",
      };
      // 导出数据
      this.exportData = {
        url: "/acb/2.0/temporaryParkingReport/exportlist",
        methods: "post",
        data: opt,
      };
    },
  },
  components: {
    myComponent,
    exportFile,
    screen,
  },
  beforeDestroy() {},
  created() {
    this.getOptionType();
  },
  computed: {},
  mounted() {
    this.searchData();
    this.getReportFieldHide();
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus" rel="stylesheet/stylus">
.pagerWrapper {
  margin-top: 0;

  .el-pagination {
    padding-top: 20px;
    padding-bottom: 20px;
    text-align: right;
  }
}

.breadcrumb {
  height: 35px;
}

.content {

  .but {
    float: right;
  }

  .list {
    margin-top: 20px;

    .el-col {
      padding-bottom: 18px;
      height: 320px;

      .grid-content {
        padding-top: 15px;
        height: 100%;
      }

      h2 {
        font-size: 18px;
        text-align: center;
      }
    }
  }
}

.info {
  margin: 0 -20px 0 -20px;
  padding-left: 20px;
  height: 40px;
  line-height: 40px;
  background: rgba(254, 242, 213, 0.47);

  .info_content {
    background: rgba(254, 242, 213, 1);
    color: rgba(255, 151, 74, 1);
  }

  img {
    width: 24px;
    height: 24px;
    vertical-align: middle;
    cursor: pointer;
  }
}

.searchWrapper >>> .el-button {

}

.tableWrapper {
  margin-top: 16px;
  position: relative;

  .switch {
    position: absolute;
    right: 0px;
    z-index: 2;
    top: 39px;
    cursor: pointer;

    img {
      height: 54px;
      width: 40px;
    }
  }
}

.tableWrapper >>> .el-table {
  width: 100% !important;
}

.tableWrapper >>> .headerCallStyle {
  height: 54px;
  font-size: 14px;
  background: #F3F7FF !important;
  padding: 5px;
  border-right: 0;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.85);

  img {
    width: 14px;
    height: 14px;
    padding-left: 5px;
  }
}

.tableWrapper >>> .headerSize {
  height: 30px;
  color: rgba(82, 96, 151, 1);
  font-size: 14px;
}

.tableWrapper >>> .headerCellQ {
  background: #fff !important;
}

.tableWrapper >>> th.el-table__cell {
  border-right: 1px solid #CFDAEF;
}

.tableWrapper >>> .headerCellW {
  background: #ffffff !important;
}

.tableWrapper >>> .el-table__body-wrapper::-webkit-scrollbar {
  width: 10px; // 横向滚动条
  height: 10px; // 纵向滚动条 必写
  background: transparent;
  border-radius: 10px;
}

// 滚动条的滑块样式
.tableWrapper >>> .el-table__body-wrapper::-webkit-scrollbar-thumb {
  background-color: rgba(217, 225, 238, 1);
  border-radius: 10px;
}

.statusContent {
  height: 110px;
  display: grid;
  grid-template-columns: 2fr 2fr 2fr 2fr 2fr 1fr;
  background: #ffffff;

  .status_info {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .blueWord {
      color: #678BFF;
    }

    .status_img {
      width: 54px;
      height: 54px;
      padding: 0 12px 0 20px;

      img {
        width: 54px;
        height: 54px;
      }
    }

    .status_word p {
      line-height: 33px;

      img {
        width: 14px;
        height: 14px;
        vertical-align: middle;
      }
    }

    .status_word span:first-child {
      color: #6C7293;
      font-size: 14px;
      vertical-align: middle;
      padding-right: 4px;
    }

    .status_word p:last-child {
      color: #454458;
      font-size: 22px;
      font-weight: 500;
    }
  }

  .status_info:last-child {
    justify-content: flex-end;
    margin-right: 30px;
  }

  .switchType {
    cursor: pointer;

    p {
      font-size: 19px;
      color: rgba(122, 130, 138, 1);
    }

    img {
      width: 16px;
      height: 16px;
      vertical-align: middle;
      margin-bottom: 1px;
    }
  }
}

.amountContents {
  padding: 0 24px 16px 24px;
  background: #ffffff;

  .amount_left {
    display: flex;
    align-items: center;
    height: 181px;
    border-left: 5px solid rgba(29, 112, 255, 0.32);
    background: rgba(151, 164, 204, 0.08);
    border-radius: 3px 0px 0px 3px;

    p {
      width: 20px;
      font-size: 16px;
      margin: 0 auto;
      color: rgba(69, 68, 88, 1);
    }
  }

  .amount_box {
    background: rgba(151, 164, 204, 0.08);
    height: 88px;

    img {
      width: 14px;
      height: 14px;
      vertical-align: middle;
    }
  }

  .amount_box p {
    line-height: 35px;
  }

  .amount_box span:first-child {
    font-size: 12px;
    vertical-align: middle;
    padding-right: 4px;
  }

  .amount_box p:first-child {
    padding: 6px 0 0 16px;
    color: rgba(108, 114, 147, 1);
    font-size: 12px;
  }

  .amount_box p:last-child {
    padding-left: 16px;
    color: rgba(69, 68, 88, 1);
    font-size: 18px;
    font-weight: 500;
  }
}
</style>
